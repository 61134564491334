import { useAppState } from '../appState'
import { navigate } from 'gatsby'
import { ROUTES } from '../constants'

const MyShoppingBoardProxy = () => {
  const [appState] = useAppState()

  if (appState.username) {
    const userShoppingBoardUrl = `/s/${appState.username}`

    // @ts-ignore-next-line
    if (window.prevLocation?.href.includes(userShoppingBoardUrl)) {
      navigate('/')
      return null
    }

    navigate(userShoppingBoardUrl)
    return null
  } else {
    navigate(ROUTES.signIn + '?redirect=' + ROUTES.myShoppingBoardProxy)
    return null
  }
}

export default MyShoppingBoardProxy
